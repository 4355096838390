<template lang="pug">
.absolute.shadow.z-30.bottom-0.top-0.left-0.bg-white.grid.grid-cols-2.pr-8.pl-10.pt-5(
  style='grid-template-rows: auto auto 1fr auto; width: 800px'
)
  h1.col-start-1.col-end-2.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("history.title") }}
  .flex.justify-end.col-start-2.items-center
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .grid.col-start-1.col-end-3.pb-3.mt-6.border-b.border-dividerColor.flex.items-center
    .col-start-1.col-end-2.pb-3.mt-6
      skif-search(
        style='width: 360px',
        v-model='filterString',
        :placeholder='$t("search")'
        @searching="searching"

      )
    .col-start-3.history-reportsDatepicker
      el-date-picker.mt-3.ml-2(
        v-model='date',
        :clearable='false',
        popper-class='history-reportsDatepicker',
        type='datetimerange',
        range-separator='-',
        value-format='yyyy-MM-dd HH:mm:ss',
        :start-placeholder='$t("reports.not-select")',
        :end-placeholder='$t("reports.not-select")',
        @change='handleDateChange'
      )
  .col-start-1.col-end-3.mt-6.overflow-y-auto.mb-2.pb-8
    table.table.w-full.table-fixed.text-left
      thead
        tr.table-row(style='height: 50px')
          th.history.table-cell.bg-white.top-0.sticky.color(
            style='width: 170px'
          ) {{ $t("history.date") }}
            svg.ml-2.transition-transform.ease-in-out.duration-500(
              @click='sortChange("created")',
              width='24',
              height='24',
              fill='none',
              xmlns='http://www.w3.org/2000/svg',
              :style='sortProp === "created" && sortOrder === "descending" && { transform: "scale(1,-1)" }'
            )
              path.transition-colors.ease-in-out.duration-200(
                d='M6.395 20a.677.677 0 00.68-.682V5.683a.683.683 0 00-.68-.682.677.677 0 00-.679.682v13.635a.683.683 0 00.68.682z',
                :fill='sortProp === "created" ? "#3C5E8F" : "#91B4E7"'
              )
              path.transition-colors.ease-in-out.duration-200(
                d='M9.591 8.893a.682.682 0 000-.966L6.876 5.2a.679.679 0 00-.962 0L3.2 7.927a.683.683 0 00.74 1.114.68.68 0 00.221-.148l2.235-2.246L8.63 8.893a.679.679 0 00.961 0zm2.235 6.335a.683.683 0 01.679-.682h4.073a.677.677 0 01.679.682.683.683 0 01-.68.681h-4.072a.677.677 0 01-.68-.681zm0-4.09a.683.683 0 01.679-.683h6.788a.678.678 0 01.68.682.683.683 0 01-.68.682h-6.788a.678.678 0 01-.68-.682zm0-4.091a.683.683 0 01.679-.682h9.504a.678.678 0 01.678.682.683.683 0 01-.678.681h-9.504a.677.677 0 01-.68-.681zm0 12.271a.683.683 0 01.679-.681h1.357a.677.677 0 01.68.681.683.683 0 01-.68.682h-1.357a.677.677 0 01-.68-.682z',
                :fill='sortProp === "created" ? "#3C5E8F" : "#91B4E7"'
              )

          th.history.table-cell.bg-white.top-0.sticky.color(
            style='width: 250px'
          ) {{ $t("history.author") }}

          th.history.table-cell.bg-white.top-0.sticky.color(
            style='width: 130px'
          ) {{ $t("admintariff.oldtariff") }}

          th.history.table-cell.bg-white.top-0.sticky.color {{ $t("admintariff.newtariff") }}

      tbody
        tr.border-b.border-dividerColor(
          style='height: 80px',
          v-for='element in filteredRoutes'
        )
          th.color.history {{ element.datepoint.slice(5, -3) }}
          th.color.history
            .flex.flex-col
              span.history {{ element.user_name }}
              //- span.history.email.mt-2 {{ "element.user.email" }}
          th(v-if='element.old_tariff').color.history {{ element.old_tariff.total }} за {{ element.old_tariff.units }} {{ $t("admintariff.historyobjects") }}
          th(v-if='element.new_tariff').color.history {{ element.new_tariff.total }} за {{ element.new_tariff.units }} {{ $t("admintariff.historyobjects") }}

  .mt-3.w-full.justify-self-center.absolute.bottom-0.bg-white.flex.justify-center.pt-2
    el-pagination(
      @prev-click='prevPage',
      @next-click='nextPage',
      @current-change='handleCurrentChange',
      layout='prev, pager, next',
      :pageSize='pageSize',
      :total='historyTable.max'
    )
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { billingApi } from '@/api'

export default {
  props: {
    // historyTable: Object,
    companyId: String
  },
  data() {
    return {
      formData: '',
      pageNumber: 1,
      pageSize: 100,
      filterString: '',
      date: ['', ''],
      sortProp: '',
      sortOrder: '',
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      moduleType: '',
      historyTable: { history: [], max: 100 }
    }
  },
  computed: {
    ...mapState('login', {
      me: (state) => state.me
    }),
    // filteredRoutes: function () {
    //   return this.historyTable.list.filter((history) => {
    //     let histories = JSON.stringify(history)
    //     return histories
    //       .toLowerCase()
    //       .includes(this.filterString.toLowerCase())
    //   })
    // },
    filteredRoutes() {
      return this.historyTable.history.reduce((result, user) => {
        // const isInArray = this.selectedUsers.map((k) => k.id).includes(user.id)
        const users = JSON.stringify(user)
          .toLowerCase()
          .includes(this.filterString.toLowerCase())
          ? [...result, user]
          : result
        return this.sortOrder === 'ascending'
          ? this.sortProp === 'created'
            ? users.sort((a, b) =>
                a.created > b.created ? 1 : b.created > a.created ? -1 : 0
              )
            : this.sortProp === 'operation'
            ? users.sort((a, b) =>
                a.operation > b.operation
                  ? 1
                  : b.operation > a.operation
                  ? -1
                  : 0
              )
            : this.sortProp === 'name'
            ? users.sort((a, b) =>
                a.user.name > b.user.name
                  ? 1
                  : b.user.name > a.user.name
                  ? -1
                  : 0
              )
            : users
          : this.sortOrder === 'descending'
          ? this.sortProp === 'created'
            ? users.sort((a, b) =>
                a.created > b.created ? -1 : b.created > a.created ? 1 : 0
              )
            : this.sortProp === 'operation'
            ? users.sort((a, b) =>
                a.operation > b.operation
                  ? -1
                  : b.operation > a.operation
                  ? 1
                  : 0
              )
            : this.sortProp === 'name'
            ? users.sort((a, b) =>
                a.user.name > b.user.name
                  ? -1
                  : b.user.name > a.user.name
                  ? 1
                  : 0
              )
            : users
          : users
      }, [])
    }
  },

  methods: {
    searching(val) {
      this.filterString = val
    },
    handleDateChange() {
      this.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.pageNumber = val
      this.getTableData()
    },
    prevPage() {
      this.pageNumber--
      // this.getTableData()
    },

    nextPage() {
      this.pageNumber++
      // this.getTableData()
    },
    getTableData() {
      //   this.$emit('show-history', {
      //     firstRow: (this.pageNumber - 1) * this.pageSize,
      //     objects: this.historyTable.objects,
      //     datesHistory: this.date,
      //   })
      const formData = {
        firstRow: (this.pageNumber - 1) * this.pageSize,
        max_rows: 100,
        from: this.date[0],
        to: this.date[1],
        companies: [{ id: this.companyId }]
      }
      billingApi.tariffHistory(
        formData,
        (response) => {
          this.historyTable.history = response.data.history
          console.log('date changed', response)
        },
        (err) => console.log('err', err)
      )
    },
    sortChange(prop) {
      if (this.sortProp === prop) {
        if (this.sortOrder === 'ascending') {
          this.sortOrder = 'descending'
        } else if (this.sortOrder === 'descending') {
          this.sortOrder = ''
          this.sortProp = ''
        } else {
          this.sortOrder = 'ascending'
        }
      } else {
        this.sortProp = prop
        this.sortOrder = 'ascending'
      }
    },
    onPaste(event) {
      const dates = event.clipboardData.getData('text').split('\n')
      if (
        dates.length === 2 &&
        moment(dates[0], 'yyyy-MM-dd HH:mm:ss').isValid() &&
        moment(dates[1], 'yyyy-MM-dd HH:mm:ss').isValid()
      ) {
        this.ruleForm.datePicker = dates
      }
    }
  },
  created() {
    this.date[0] = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    this.date[1] = moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss')
    const formData = {
      max_rows: 100,
      first_row: 0,
      from: this.date[0],
      to: this.date[1],
      companies: [{ id: this.companyId }]
    }
    billingApi.tariffHistory(
      formData,
      (response) => {
        console.log('tarrif', response.data)
        this.historyTable.history = response.data.history
      },
      (err) => console.log('err', err)
    )
    console.log('si', this.historyTable)
  }
  //   created() {
  //     // this.formData = this.$parent.formDataHistory

  //     // this.date[0] = new Date(this.formData.from)
  //     // this.date[1] = new Date(this.formData.to)
  //     // this.dateFormat = this.me.active_company.dateformat.key
  //     // this.timeFormat = this.me.active_company.timeformat.key
  //     // this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
  //   },
}
</script>
<style>
.history-reportsDatepicker .el-date-editor .el-range-separator {
  line-height: 27px;
}
</style>
<style scoped>
.history {
  color: #5477a9;
  font-size: 14px;
  font-weight: bold;
}
.email {
  color: #95baec;
  font-size: 14px;
}
.color {
  color: #5477a9;
}
</style>
